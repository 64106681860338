import { BusEventBase, BusEventWithPayload } from '@grafana/data';
import { Collectable, Investigation } from './types';

export class SelectInvestigationEvent extends BusEventWithPayload<Investigation> {
  static type = 'select-investigation';
}

export class DeleteEvent extends BusEventWithPayload<{ id: string; confirm?: boolean; investigation?: Investigation }> {
  static type = 'delete';
}

export class AddCollectableEvent extends BusEventWithPayload<Collectable> {
  static type = 'add-collectable';
}

export class DeleteCollectableEvent extends BusEventWithPayload<{ id: string; confirm?: boolean }> {
  static type = 'delete-collectable';
}

export class BackEvent extends BusEventBase {
  static type = 'back';
}

export class HighlightCollectableEvent extends BusEventWithPayload<{ id: string; wobble?: boolean }> {
  static type = 'highlight-collectable';
}

export interface ChangeCollectableNoteInterface {
  investigationId: string;
  collectableId: string;
  note: string;
}

export class ChangeCollectableNote extends BusEventWithPayload<ChangeCollectableNoteInterface> {
  static type = 'change-collectable-note';
}

export class UpdateInvestigationEvent extends BusEventWithPayload<Investigation> {
  static type = 'update-investigation';
}

export interface ChangeInvestigationSummaryInterface {
  investigationId: string;
  summary: string;
}

export class ChangeInvestigationSummary extends BusEventWithPayload<ChangeInvestigationSummaryInterface> {
  static type = 'change-investigation-summary';
}

export interface TitleEventInterface {
  title: string;
  id?: string;
  editable?: boolean;
  showControls?: boolean;
  hasCustomName?: boolean;
}

export class TitleEditEvent extends BusEventWithPayload<TitleEventInterface> {
  static type = 'title-edit';
}

export class TitleModeChangeEvent extends BusEventWithPayload<TitleEventInterface> {
  static type = 'title-mode-change';
}

export class TitleSavedEvent extends BusEventWithPayload<{ title: string; id: string }> {
  static type = 'title-saved';
}
